<template>
  <div
    id="userFluidLayoutHeaderSearchBar"
    class="user-fluid-layout__header-search-bar"
  >
    <b-input-group
      :class="{
        active: isFocused,
        'user-fluid-layout__search-clear': showClearIcon
      }"
    >
      <b-input-group-prepend is-text>
        <img
          @click="onSearch"
          :src="
            isFocused
              ? require(`@/assets/images/layout/user-fluid-layout/search-icon-active.svg`)
              : require(`@/assets/images/layout/user-fluid-layout/search-icon.svg`)
          "
          alt="search"
          width="18"
          height="18"
        />
      </b-input-group-prepend>
      <b-form-input
        :placeholder="$t('general.search')"
        v-model.trim="search"
        @focus="onInputFocus"
        @blur="isFocused = false"
        @keyup.enter="onSearch"
        class="user-fluid-layout__search--input"
      ></b-form-input>
      <b-input-group-append is-text>
        <img
          src="@/assets/images/layout/user-fluid-layout/close.svg"
          alt="close"
          width="14"
          height="14"
          class="d-none d-sm-block d-md-block d-lg-block"
          v-if="search !== ''"
          @click="deleteSearch"
        />
      </b-input-group-append>
    </b-input-group>
    <div
      class="ufl-search-bar__menu"
      v-if="
        showSearchHistory && isSearchBarClicked && getSearchHistory.length > 0
      "
    >
      <ul class="ufl-search-bar__menu--ul">
        <li class="ufl-search-bar__menu--li">
          <div
            class="ufl-search-bar__menu--div"
            v-for="(list, i) in getSearchHistory"
            :key="i"
          >
            <div
              class="flex-grow-1 ufl-search-bar__menu--left"
              @click="setSearch(list)"
            >
              <img
                src="@/assets/images/layout/user-fluid-layout/search-history.svg"
                alt="history"
                class="ufl-search-bar__menu--img"
              />
              <p class="ufl-search-bar__menu--text">{{ list.search_text }}</p>
            </div>
            <img
              src="@/assets/images/layout/user-fluid-layout/close.svg"
              alt="close"
              width="14"
              height="14"
              class="ml-auto ufl-search-bar__menu--img"
              @click="deleteHistory(list)"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      isFocused: false,
      showSearchHistory: false,
      isSearchBarClicked: false
    };
  },
  computed: {
    ...mapGetters(["getSearchText", "getSearchHistory"]),
    showClearIcon() {
      return this.search !== "" && this.$route.name === "course-discovery";
    }
  },
  watch: {
    getSearchText(newVal) {
      if (newVal !== this.search) {
        this.search = newVal;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.onDocClick);
    if (this.getSearchHistory.length === 0) {
      this.$store.dispatch("listSearchHistory");
    }
  },
  methods: {
    onFocus() {
      this.showSearchHistory = false;
      this.$emit("onFocus");
    },
    onSearch(newSearch = true) {
      this.onFocus();
      if (this.search !== "" && newSearch) {
        this.$store.dispatch("saveAUserSearch", { search_text: this.search });
      }
      this.$store.commit("SET_SEARCH_TEXT", this.search);
    },
    onSearchClear() {
      this.search = "";
      if (this.$route.name === "course-discovery") {
        this.onFocus();
        this.$store.commit("SET_SEARCH_TEXT", this.search);
      }
    },
    onInputFocus() {
      this.isFocused = true;
      this.showSearchHistory = true;
    },
    deleteHistory(list) {
      this.$store.dispatch("deleteASearchHistory", list);
    },
    onDocClick(event) {
      let className = event.target.className;
      if (
        className.includes("ufl-search-bar__menu") ||
        className.includes("user-fluid-layout__search")
      ) {
        this.isSearchBarClicked = true;
      } else {
        this.isSearchBarClicked = false;
      }
    },
    setSearch(search) {
      this.search = search.search_text;
      this.showSearchHistory = false;
      this.onSearch(false);
    },
    deleteSearch() {
      this.onSearchClear();
      this.$emit("closeSearch");
    }
  }
};
</script>

<style lang="scss">
$border-radius-px: 100px;
@mixin active-searchbar {
  border: 1px solid $brand-primary;
  border-radius: $border-radius-px;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  .input-group-text,
  .form-control {
    background: $brand-primary-75;
  }
}
.user-fluid-layout__header-search-bar {
  position: relative;
  padding: 0 8px;
  .input-group {
    border: 1px solid transparent;
    box-shadow: 0px 4px 8px 4px rgb(33 33 33 / 10%);
    border-radius: 100px;
    margin-top: -5px;
    z-index: 0;
    &.active,
    &:focus {
      @include active-searchbar;
      z-index: 1;
    }
    &:hover {
      background: $brand-primary-100;
      .input-group-text,
      .form-control {
        background: $brand-primary-100;
      }
    }
    .input-group-text {
      cursor: pointer;
      padding: 15px 15px 15px 20px;
    }
    .input-group-append {
      .input-group-text {
        padding: 15px 20px 15px 15px;
        border-radius: 0 100px 100px 0px;
      }
    }
    .input-group-text,
    .form-control {
      height: 40px;
      background-color: $brand-neutral-0;
      border: none;
      color: $brand-primary;
      border-radius: $border-radius-px;
    }
    .form-control {
      padding: 15px 20px 15px 0px;
      color: $ufl-search-color;

      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: $brand-neutral-200;
      }
    }
  }
  .ufl-search-bar__menu {
    position: absolute;
    width: calc(100% - 16px);
    background: $brand-neutral-0;
    box-shadow: 0px 4px 8px 4px rgb(33 33 33 / 10%);
    border-radius: 4px;
    top: 48px;
    z-index: 1;
    max-height: 290px;
    overflow-y: auto;
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      .ufl-search-bar__menu--div {
        @include horizontal-space-between;
        padding: 0px 16px 0 0;
        cursor: pointer;
        &:hover {
          background: $brand-primary-100;
        }
        .ufl-search-bar__menu--left {
          display: flex;
          padding: 12px 0 12px 16px;
          p {
            margin: 0 0 0 10px;
          }
        }
      }
    }
  }
}
[dir="rtl"] {
  .input-group {
    &.user-fluid-layout__search-clear {
      margin-right: 1rem;
      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .input-group-prepend {
      .input-group-text {
        border-radius: 0 100px 100px 0px;
        padding: 15px 20px 15px 15px;
      }
    }
    .input-group-append {
      .input-group-text {
        border-radius: 100px 0px 0px 100px;
        padding: 15px 15px 15px 20px;
      }
    }
    .form-control {
      padding: 15px 0px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }
  }
  .ufl-search-bar__menu {
    ul {
      padding: 0;
      .ufl-search-bar__menu--div {
        .ufl-search-bar__menu--left {
          p {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
}
</style>
