var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-fluid-layout__header-search-bar",attrs:{"id":"userFluidLayoutHeaderSearchBar"}},[_c('b-input-group',{class:{
      active: _vm.isFocused,
      'user-fluid-layout__search-clear': _vm.showClearIcon
    }},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('img',{attrs:{"src":_vm.isFocused
            ? require(`@/assets/images/layout/user-fluid-layout/search-icon-active.svg`)
            : require(`@/assets/images/layout/user-fluid-layout/search-icon.svg`),"alt":"search","width":"18","height":"18"},on:{"click":_vm.onSearch}})]),_c('b-form-input',{staticClass:"user-fluid-layout__search--input",attrs:{"placeholder":_vm.$t('general.search')},on:{"focus":_vm.onInputFocus,"blur":function($event){_vm.isFocused = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[(_vm.search !== '')?_c('img',{staticClass:"d-none d-sm-block d-md-block d-lg-block",attrs:{"src":require("@/assets/images/layout/user-fluid-layout/close.svg"),"alt":"close","width":"14","height":"14"},on:{"click":_vm.deleteSearch}}):_vm._e()])],1),(
      _vm.showSearchHistory && _vm.isSearchBarClicked && _vm.getSearchHistory.length > 0
    )?_c('div',{staticClass:"ufl-search-bar__menu"},[_c('ul',{staticClass:"ufl-search-bar__menu--ul"},[_c('li',{staticClass:"ufl-search-bar__menu--li"},_vm._l((_vm.getSearchHistory),function(list,i){return _c('div',{key:i,staticClass:"ufl-search-bar__menu--div"},[_c('div',{staticClass:"flex-grow-1 ufl-search-bar__menu--left",on:{"click":function($event){return _vm.setSearch(list)}}},[_c('img',{staticClass:"ufl-search-bar__menu--img",attrs:{"src":require("@/assets/images/layout/user-fluid-layout/search-history.svg"),"alt":"history"}}),_c('p',{staticClass:"ufl-search-bar__menu--text"},[_vm._v(_vm._s(list.search_text))])]),_c('img',{staticClass:"ml-auto ufl-search-bar__menu--img",attrs:{"src":require("@/assets/images/layout/user-fluid-layout/close.svg"),"alt":"close","width":"14","height":"14"},on:{"click":function($event){return _vm.deleteHistory(list)}}})])}),0)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }