import { createLocalVue, mount } from "@vue/test-utils";
import i18next from "@/i18next";
import Vuex from "vuex";
import { BFormInput, BInputGroup, BInputGroupPrepend } from "bootstrap-vue";

import { waitRAF } from "@/../tests/utils.js";
import SearchBar from "../SearchBar";

// create an extended `Vue` constructor
const localVue = createLocalVue();
localVue.use(Vuex);
localVue.component("b-form-input", BFormInput);
localVue.component("b-input-group", BInputGroup);
localVue.component("b-input-group-prepend", BInputGroupPrepend);

describe("SearchBar.vue", () => {
  it("render when created ", async () => {
    let wrapper = await getWrapper();
    expect(wrapper.exists()).toBeTruthy();
    expect(wrapper.element).toMatchSnapshot();
    wrapper.destroy();
  });
  describe("Data", () => {
    let wrapper;

    beforeEach(async () => {
      wrapper = await getWrapper();
    });
    afterEach(() => {
      wrapper.destroy();
    });

    it("should render styles for search bar when `isFocused` is true", async () => {
      await wrapper.setData({ isFocused: true });
      await waitRAF();
      const focusedBar = wrapper.find(".input-group.active");
      expect(focusedBar.exists()).toBe(true);
    });
  });

  describe("Events", () => {
    let wrapper;

    beforeEach(async () => {
      wrapper = await getWrapper();
    });

    afterEach(() => {
      wrapper.destroy();
    });

    it("should emit `onFocus` when search bar is focused", async () => {
      let input = wrapper.find(".input-group-prepend .input-group-text img");
      await input.trigger("click");
      expect(wrapper.emitted().onFocus).toBeTruthy();
    });
  });
});

function getWrapper() {
  let state = {
      searchText: "",
      searchHistory: []
    },
    mutations = {
      SET_SEARCH_TEXT(state, searchText) {
        state.searchText = searchText;
      }
    },
    getters = {
      getSearchText: state => state.searchText,
      getSearchHistory: state => state.searchHistory
    },
    actions = {
      getSearchHistory: jest.fn()
    };
  let store = new Vuex.Store({
    state,
    mutations,
    getters,
    actions
  });

  let wrapper = mount(SearchBar, {
    localVue,
    i18n: i18next,
    store
  });

  return wrapper;
}
